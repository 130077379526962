import { Application, defaultSchema } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete"

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, slash: "/" },
}

const application = Application.start(document.documentElement, customSchema)

application.register("autocomplete", Autocomplete)

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }
